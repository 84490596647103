import React from 'react'
import PointCloudView from "./components/_point-cloud";
import {BatchPcdLoader, PcdLoader} from "../../loaders/_pcd-loader";
import {parse} from "query-string";
import {document} from 'browser-monads';


export default function PointCloudViewer({}) {
  // Default voxel size is 10mm = 1cm = 0.01m
  let {
    path = "",
    urls = "",
    ws = "",
    voxelSize = "",
    fit = "",
    rotation = "",
    translation = "",
    background = "",
    collapsed = "",
  } = parse(document.location.search)

  const params = {
    urls: urls.split(',').filter(u => !!u),
    path,
    ws,
    voxelSize: (!!voxelSize) ? parseFloat(voxelSize) : null,
    fit: fit === 'true',
    rotation: rotation.split(',').filter(u => u && u === u).map(parseFloat),
    translation: translation.split(',').filter(u => u && u === u).map(parseFloat),
    background,
    collapsed: ['true', 'True', "1"].includes(collapsed),
  }

  if (typeof window == "undefined") return <div>loading</div>;
  return (<>
    <helmet>
      <title>Point Cloud Demo</title>
      <style>{`
            body {
                margin: 0px 0px 0px 0px;
            }
            body {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
            body > #___gatsby,
            body > #___gatsby > div,
            body > #___gatsby > div > div:not([class*="leva-"]),
            canvas {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
            `}</style>
    </helmet>
    {
      urls.length
          ? <BatchPcdLoader component={PointCloudView} width={640} height={480} {...params}/>
          : <PcdLoader url={path} component={PointCloudView} height={480} {...params}/>
    }
  </>)
}